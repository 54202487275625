@use 'variables';
@use 'mixins';

*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
}



body{
    font-family: map-get(variables.$font, main);
    font-style: normal;

    // .home{
    //     padding: 20px 60px;
    // }

    .header{
        @include mixins.flexBet;
        width: 100%;
        padding: 20px 60px;
        position: fixed;
        top: 0;
        z-index: 1000;
        background-color: #FFF;
        
      
        nav {
          @include mixins.flexer;
      
          ul {
                @include mixins.flexEven;
                gap: 30px;
        
                li {
                    text-transform: uppercase;
            
                    a {
                            color: map-get(variables.$colors, links);
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 30px;
                
                            &.active-link {
                            color: map-get(variables.$colors, main);
                            }
                    }
                }
            }
        }
    }
      

    .hero{
        @include mixins.flexBet;
        width: 100%;
        height: 580px;
        background: url(../images/redRec.png) no-repeat right;
        padding: 20px 60px;
        
        div{
            @include mixins.flexColStart;
            gap: 29px;            
            width: 400px;
            height: 300px;
            color: #fff;

            h1{
                @include mixins.head(111.2, -1);
                line-height: 89px;
            }

            p{
                @include mixins.para(16);
                height: 52px;
                width: 430px;
                color: #000;
            }

            a{
                @include mixins.cta(1);
                text-transform: capitalize;
                // color: #000;
            }
        }

        img{
            position: relative;
            left: 5.25%;
            display: none;
        }
    }

    .holding{
        @include mixins.flexCol;
        width: 100%;
        margin-top: 80px;
    }

    .home{
        @include mixins.flexCol;
        // gap: 20px;

        .us{
            @include mixins.flexer;
            padding: 20px 60px;
            margin: 40px 0;
            gap: 70px;
            width: 100%;
            .img{
                width: 556px;
                height: 660px;
                border-radius: 64px;
            }

            .text{
                @include mixins.flexColStart;
                gap: 20px;

                h4{
                    @include mixins.head(18, 1);
                    line-height: 23px;
                    color: map-get(variables.$colors, main );
                }

                h2{
                    @include mixins.head(56, -1);
                }

                p{
                    @include mixins.para(18);
                    width: 563px;
                    height: 348px;
                }

                a{
                    @include mixins.cta(1);
                }
            }
        }

        .service{
            @include mixins.flexCol;
            gap: 40px;
            padding: 20px 60px;
            width: 100%;

            .top{
                @include mixins.flexCol;

                h4{
                    @include mixins.head(18, 1);
                }

                h2{
                    @include mixins.head(56, -1);
                    text-align: center;
                    width: 606px;
                }
            }

            .bot{
                @include mixins.flexer;
                // height: 100vh;

                .grider{
                    position: relative;
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    grid-template-rows: repeat(2, auto); // Adjust the rows as needed
                    gap: 50px;
                    left: 10px;

                    div{
                        @include mixins.flexStart;
                        gap: 13px;
                        width: 373px;

                        span{
                            @include mixins.flexColStart;
                            width: 294px;

                            h4{
                                @include mixins.head(32, -1);
                                line-height: 39px;
                            }

                            p{
                                @include mixins.para(18);
                                letter-spacing: 0.27px;
                                width: 292px;
                            }
                        }
                    }
                }
            }

            a{
                @include mixins.cta(1);
            }
        }

        .process{
            @include mixins.flexCol;
            gap: 60px;
            background: map-get(variables.$colors, tran );
            width: 100%;
            padding: 70px 60px;

            .top{
                @include mixins.flexCol;

                h4{
                    @include mixins.head(18, 0);
                    line-height: 23px;
                }

                h2{
                    @include mixins.head(56, 0);
                }

                p{
                    @include mixins.para(18);
                    color: #fff;
                }
            }

            .bot{
                @include mixins.flexer;
                gap: 30px;
                
                div{
                    width: 267px;
                    height: 342px;
                    border-radius: 64px;
                    background: #666;
                    @include mixins.flexCol;
                    gap: 15px;

                    h4{
                        @include mixins.head(32, 0);
                        line-height: 39px;
                    }

                    p{
                        @include mixins.para(14);
                        line-height: 22px;
                        color: #D8D8D8;
                        text-align: center;
                        width: 203px;
                    }
                }
            }

            a{
                @include mixins.cta(1);
            }
        }

        .test{
            @include mixins.flexCol;
            padding: 20px 60px;
            width: 100%;
            background: #F9F9F9;
            gap: 40px;

            .top{
                @include mixins.flexColStart;
                width: 100%;
                
                h4{
                    @include mixins.head(18, 1);
                    line-height: 23px;
                }

                .tBot{
                    @include mixins.flexBet;
                    width: 100%;

                    h2{
                        @include mixins.head(56, -1);
                    }

                    .but{
                        @include mixins.flexer;
                        gap: 3px;

                        button{
                            width: 15px;
                            height: 28px;
                        }
                    }
                }
            }

            .mid{
                @include mixins.flexer;
                gap: 15px;
                min-width: 100%;
                overflow: hidden;

                .testimonial{
                    width: 350px;
                    height: 260px;
                    border-radius: 64px;
                    background: #FFF;
                    @include mixins.flexColStart;
                    padding: 10px;
                    gap: 18px;
                    // min-width: 100%;

                    p{
                        @include mixins.para(16);
                        line-height: 18px;
                    }

                    span{
                        @include mixins.flexBet;
                        width: 320px;
                        height: 66px;
                        border-radius: 48px;
                        border: 1px solid #D8D8D8;
                        background: #F9F9F9;
                        padding: 10px;

                        .hold{
                            @include mixins.flexer;
                            gap: 10px;

                            .img{
                                width: 56px;
                                height: 56px;
                                border-radius: 56px;
                            }

                            .text{
                                @include mixins.flexColStart;

                                h5{
                                    @include mixins.head(18, -1);
                                    line-height: 23px;
                                }

                                p{
                                    @include mixins.para(14);
                                    line-height: 18px;
                                    color: #949494;
                                }
                            }
                        }
                    }
                }
            }

            .line{
                border: 1px solid #D8D8D8;
                width: 100%;
            }

            .bot{
                @include mixins.flexer;

                ul{
                    @include mixins.flexer;
                    gap: 30px;
                    // justify-self: center;

                    li{
                        @include mixins.flexer;
                        gap: 12px;

                        div{
                            @include mixins.flexColStart;

                            h5{
                                @include mixins.head(48, -1);
                                line-height: 59px;
                            }

                            p{
                                @include mixins.para(18);
                                letter-spacing: 0.2px;
                                line-height: normal;
                                color: #585858;
                            }
                        }   
                    }
                }
            }
        }

        .clients{
            height: 600px;
            gap: 80px;

            h1{
                @include mixins.head(56, -1);
                word-wrap: break-word;
                width: 556px;
                text-align: center;
            }

            .Marquee{
                @include mixins.flexLineStart;
                gap: 19.597px;
                box-shadow: 0px 12px 72px 0px rgba(0, 0, 0, 0.08);
                background: rgba(0, 0, 0, 0.08);

                .img{
                    @include mixins.flexer;
                    gap: 20px;

                    div{
                        @include mixins.flexer;
                        width: 171.477px;
                        height: 171.477px;
                        border-radius: 48.993px;
                        background: var(--White, #FFF);
                    }
                }
            }
        }

        
    }

    .footer{
        @include mixins.footer;
    }

    .about{
        @include mixins.flexCol;

        // .header{
        //     background: none;
        // }

        .hero{
            background: url(../images/about.png) no-repeat center ;

            img{
                display: block;
            }

            h1{
                color: #FFF;
            }
        }

        .who{
            padding: 80px 60px;
            @include mixins.flexer;
            gap: 50px;

            .img{
                position: relative;
                right: 34px;
                width: 556px;
                height: 660px;
                border-radius: 64px;
                background: url(../images/group-afro-americans-working-together\ \(1\)\ 1.png);
            }

            .text{
                position: relative;
                right: 15px;
                @include mixins.flexColStart;
                gap: 15px;
                width: 500px;

                h1{
                    @include mixins.head(56, -1);
                }

                p{
                    @include mixins.para(18);
                    height: 290px;
                    word-wrap: break-word;
                    color: #585858;
                    text-align: justify;
                }
            }
        }

        .test{
            @include mixins.testi;
            padding: 80px 60px;
            background-color: #FFF;
        }

        .misVis{
            @include mixins.flexCol;
            background: var(--Grey-6, #F9F9F9);
            padding: 0 60px 60px;
            gap: 60px;

            .top{
                @include mixins.flexer;

                div{
                    @include mixins.flexer;
                    width: 474px;
                    height: 126.4px;
                    align-self: stretch;
                    background: #fff;
                    cursor: pointer;
                    border-bottom: 4px solid transparent;

                    &.active{
                        border-bottom-color: #e7454c;
                    }

                    &.inactive {
                        h1{
                            color: #949494;
                        }
                    }

                    h1{
                        @include mixins.head(48, -1);

                        &.inactive {
                            color: #949494;
                        }
                    }
                }
            }

            .bot{
                p{
                    @include mixins.para(18);
                    text-align: center;
                    color: var(--Grey-1, #585858);
                }
            }
        }
    }
//
    .contact{
        @include mixins.flexCol;

        // .header{
        //     background: none;
        // }
        
        .hero{
            background: url(../images/contact.png) no-repeat center ;

            img{
                display: block;
            }

            div{
                width: 420px;

                h1{
                    color: #FFF;
                }
            }
        }

        .feel{
            padding: 40px 60px;
            @include mixins.flexCol;
            gap: 30px;
            width: 100px;

            .top{
                @include mixins.flexCol;
                width: 457px;

                h1{
                    @include mixins.head(56, -1);
                }

                p{
                    @include mixins.para(18);
                    color: #585858;
                }
            }

            .bot{
                @include mixins.flexer;
                gap: 30px;

                .hold{
                    @include mixins.flexCol;
                    width: 364px;
                    height: 314px;
                    border-radius: 64px;
                    background: var(--Grey-6, #F9F9F9);
                    gap: 15px;

                    .img{
                        width: 96px;
                        height: 96px;
                        background: url(../images/loc.svg) 50% 33% no-repeat;
                        filter: drop-shadow(0px 12px 72px rgba(0, 0, 0, 0.08));
                        border-radius: 50%;
                    }

                    .img2{
                        background-image: url(../images/mail.svg);
                    }

                    .img3{
                        background-image: url(../images/call.svg);
                    }

                    h4{
                        @include mixins.head(32, -1);
                        line-height: 39px;
                    }

                    p{
                        @include mixins.para(18);
                        color: #585858;
                        text-align: center;
                    }
                }
            }
        }

        .touch{
            @include mixins.flexer;
            padding: 40px 60px;
            gap: 30px;

            .form{
                position: relative;
                @include mixins.flexColStart;
                gap: 8px;
                right: 20px;

                h1{
                    @include mixins.head(56, -1);
                }

                p{
                    @include mixins.para(18);
                    color: #585858;
                }

                form{
                    @include mixins.flexColStart;
                    gap: 15px;

                    label{
                        @include mixins.head(16, -1);
                        line-height: 20px;
                    }

                    input{
                        width: 554px;
                        height: 48px;
                        flex-shrink: 0;
                        border-radius: 8px;
                        border: 1px solid var(--Grey-5, #D8D8D8);
                        background: var(--White, #FFF);
                        padding: 10px;

                        &::placeholder{
                            @include mixins.para(14);
                            color: #949494;
                            line-height: 18px;
                        }
                    }

                    .hold{
                        @include mixins.flexColStart;

                        textarea{
                            width: 554px;
                            height: 144px;
                            flex-shrink: 0;
                            border-radius: 8px;
                            border: 1px solid var(--Grey-5, #D8D8D8);
                            background: var(--White, #FFF);
                            padding: 10px;
                        }
                    }
                    
                    #hold{
                        @include mixins.flexer;
                        gap: 30px;

                        div{
                            @include mixins.flexColStart;
                            gap: 10px;

                            input{
                                width: 250px;
                                height: 40px;
                                flex-shrink: 0;
                                border-radius: 8px;
                                border: 1px solid var(--Grey-5, #D8D8D8);
                                background: var(--White, #FFF);
                            }
                        }
                    }

                    button{
                        @include mixins.cta(1);
                        width: 554px;
                        cursor: pointer;
                    }
                }
            }

            .img{
                position: relative;
                @include mixins.flexColLineStart;
                height: 660px;
                width: 556px;
                border-radius: 64px;
                background: url(../images/smiling-portrait-confident-young-businesswoman-holding-digital-tablet-talking-mobile-phone\ 1.png) no-repeat;
                left: 25px;
            }
        }

        .test{
            @include mixins.testi;
            padding: 80px 60px;
            background-color: #FFF;
        }
    }

    .serv{
        @include mixins.flexCol;
        
        .hero{
            background: url(../images/services.png) no-repeat center ;

            img{
                display: block;
            }

            div{
                width: 480px;

                h1{
                    color: #FFF;
                }

                p{
                    color: #FFF;
                }
            }
        }

        .lHold{
            @include mixins.flexer;
            padding: 80px 60px;
            gap: 50px;

            .img{
                position: relative;
                right: 20px;
                width: 556px;
                height: 660px;
                border-radius: 64px;
            }

            .text{
                position: relative;
                left: 20px;
                @include mixins.flexColStart;
                gap: 10px;

                h2{
                    @include mixins.head(56, -1);
                }

                p{
                    @include mixins.para(18);
                    color: #585858;
                }

                ul{
                    @include mixins.flexColStart;
                    gap: 10px;

                    li{
                        @include mixins.flexer;
                        gap: 8px;
                        @include mixins.para(18);
                        color: #585858;

                        .red{
                            width: 16px;
                            height: 16px;
                            background: #E7454C;
                        }

                    }
                }
            }
        }

        .rHold{
            @include mixins.flexer;
            padding: 80px 60px;
            gap: 50px;

            .img{
                position: relative;
                left: 20px;
                width: 556px;
                height: 660px;
                border-radius: 64px;
            }

            .text{
                position: relative;
                right: 20px;
                @include mixins.flexColStart;
                gap: 10px;

                h2{
                    @include mixins.head(56, -1);
                }

                p{
                    @include mixins.para(18);
                    color: #585858;
                }

                ul{
                    @include mixins.flexColStart;
                    gap: 10px;

                    li{
                        @include mixins.flexer;
                        gap: 8px;
                        @include mixins.para(18);
                        color: #585858;

                        .red{
                            width: 16px;
                            height: 16px;
                            background: #E7454C;
                        }

                    }
                }
            }
        }
    }

    .lead {
        @include mixins.flexCol;

        // .header{
        //     background: none;
        // }
    
        .hero{
            background: url(../images/leader.png) no-repeat center ;

            img{
                display: block;
            }

            div{
                width: 565px;

                h1{
                    color: #FFF;
                }

                p{
                    color: #FFF;
                }
            }
        }
    
        .board {
            margin: 30px; // Change padding to margin if you want spacing around the entire board
            @include mixins.flexCol;
            gap: 80px;
            row-gap: 50px;
            column-gap: 80px;

    
            h1 {
                @include mixins.head(56, -1);
            }
    
            .hold {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-template-rows: repeat(4, 1fr);
                gap: 25px;
    
                .holder {
                    @include mixins.flexCol;
    
                    img {
                        width: 280px;
                        height: 400px;
                        border-radius: 72.316px;

                        // @include mixins.of-type(1){
                        //     height: 350px;
                        // }

                        // @include mixins.of-type(9){
                        //     position: relative;
                        //     right: 50%;
                        //     width: 500px;
                        // }
                    }

                    &:last-child{
                        position: relative;
                        left: 109%;
                    }

                    &:nth-child(2){
                        height: 360px;
                        align-self: flex-end;
                    }
    
                    h5 {
                        @include mixins.head(32, 0);
                        color: #818285;
                        line-height: 39px;
                    }
    
                    p {
                        @include mixins.para(16);
                        color: #585858;
                    }
    
                    &:hover {
                        cursor: pointer;
                        // box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
                        transition: box-shadow 0.3s ease-in-out;
                    }
                }
            }

            .modal-overlay {
                background: rgba(0, 0, 0, 0.7); /* Adjust the opacity as needed */
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 997; /* Ensure it appears below the modal */
                visibility: hidden;
                opacity: 0;
                transition: opacity 0.3s ease-in-out;
            }
    
            .modal {
                padding: 65px 20px 70px;
                width: 990px;
                height: 510px;
                border-radius: 50px;
                background: var(--White, #FFF);
                @include mixins.flexCol;
                position: fixed;
                top: 45%;
                left: 45%;
                margin-top: -200px;
                margin-left: -425px;
                transition: opacity 0.3s ease-in-out;
                opacity: 0;
    
                &.open {
                    opacity: 1;
            
                    .modal-overlay {
                        visibility: visible;
                        opacity: 1;
                        z-index: 999;
                        box-shadow: 0 0 40px rgba(0, 0, 0, 0.5); // Even box shadow
                    }
                }
            
    
                button {
                    position: absolute;
                    top: 20px;
                    right: 20px;
                    width: 30.526px;
                    height: 30.526px;
                    border: none;
                    @include mixins.flexer;
                    background: url(../images/iconoir_cancel.svg) no-repeat center;
    
                    &:hover {
                        cursor: pointer;
                    }
                }
    
                div {
                    @include mixins.flexer;
                    gap: 20px;
    
                    img {
                        width: 302.863px;
                        height: 387.937px;
                        border-radius: 72.596px;
                    }
    
                    span {
                        @include mixins.flexColStart;
                        gap: 8px;
    
                        h5 {
                            @include mixins.head(48, 0);
                            color: #818285;
                            line-height: 59px;
                        }
    
                        p {
                            @include mixins.para(16);
                            line-height: 34.342px;
                            text-align: justify;
                        }
                    }
                }
    
                .modal-overlay {
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba(0, 0, 0, 0.5);
                    z-index: 998; // Ensure it appears below the modal
                    visibility: hidden;
                    opacity: 0;
                    transition: opacity 0.3s ease-in-out;
                }
            }
    
            &.modal-open {
                overflow: hidden; // Prevent scrolling when modal is open
    
                // .modal {
                //     // Additional styles for modal-open if needed
                // }
            }
        }
    
        .modal-open {
            overflow: hidden; // Prevent scrolling when modal is open
        }
    }
    
    
    
    //
}
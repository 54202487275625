@use 'variables';

@mixin flexer{
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flexLine{
  @include flexer;
  display: inline-flex;
}

@mixin flexLineStart{
  @include flexLine();
  align-items: flex-start;
}

@mixin flexColLineStart{
  @include flexLineCol();
  align-items: flex-start;
}

@mixin flexEnd{
  @include flexer;
  align-self: flex-end;
}

@mixin flexLineCol{
  @include flexLine;
  flex-direction: column;
}

@mixin flexCol{
  @include flexer;
  flex-direction: column;
}

@mixin flexBet{
  @include flexer;
  justify-content: space-between;
}

@mixin flexEven{
  @include flexer;
  justify-content: space-evenly;
}

@mixin flexColEven{
  @include flexCol;
  justify-content: space-evenly;
}

@mixin flexColBet{
  @include flexCol;
  justify-content: space-between;
}

@mixin flexStart{
  @include flexer;
  align-items: flex-start;
}

@mixin flexColStart{
  @include flexCol;
  align-items: flex-start;
}

@mixin query ($breakpoint){
  @if $breakpoint == mobile{
    @media only screen and (max-width: 481px) {
      @content;
    }
  }

  @if $breakpoint == tablet {
    @media only screen and (max-width: 768px) {
      @content;
    }
  }
}

// @mixin cta{
//   display: inline-block;
//   width: 351px;
//   height: 64px;
//   // display: flex;
//   align-items: center;
//   justify-content: center;
//   border-radius: 8px;
//   padding: 0 4px 4px 0;
//   position: relative; /* Add position: relative; for pseudo-element */

//   @include query(mobile){
//     width: 237.517px;
//     height: 48px;
//   }

//   &::before {
//     content: "";
//     background-image: url(../../images/cta.svg);
//     background-size: cover;
//     background-repeat: no-repeat;
//     width: 100%;
//     height: 100%;
//     position: absolute;
//     top: 0;
//     left: 0;
//     z-index: -1; /* Move the pseudo-element behind the content */
//     transform: scaleY(-1) scaleX(-1); /* Flip the background image */
//     border-radius: 8px;
//   }


//   a{
//     color: #fff;
//     text-transform: capitalize;
//     font-style: 16px;
//     font-weight: 500;
//     line-height: 16px;
//     background-color: map-get(variables.$colors , cta );
//     width: 100%;
//     height: 100%;
//     border-radius: 8px;
//     @include flexer;
//   }
// }

@mixin of-type($num){
  @if $num == 1{
    &:nth-of-type(1){
      @content;
    }
  }

  @else if $num == 2{
    &:nth-of-type(2){
      @content;
    }
  }

  @else if $num == 3{
    &:nth-of-type(3){
      @content;
    }
  }

  @else if $num == 4{
    &:nth-of-type(4){
      @content;
    }
  }

  @else if $num == 5{
    &:nth-of-type(5){
      @content;
    }
  }
}

@mixin para($num){
    font-size: $num * 1px;
    font-weight: 400;
    line-height: 29px;
    font-family: map-get(variables.$font, para);

  
    @include query(mobile){
      text-align: center;
      font-size: 14px;
      line-height: 26.5px;
    }
}

@mixin head($num, $coll){
  font-size: $num * 1px;
  font-weight: 700;
  line-height: 69px;

  @if $coll == 1{
    color: map-get(variables.$colors , main );
  } @else if $coll == 0{
    color: #fff;
  } @else if $coll == -1{
    color: #000;
  }

  // @include query(mobile){
  //   text-align: center;
  //   font-size: 24px;
  //   line-height: 34px;
  // }

  
}

// @mixin ifState($num){
//   @if $num
// }

@mixin cta($col){
  @include flexer;
  padding: 20px 55px;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #fff;
  color: #fff;

  @if $col == 1{
    background-color: map-get(variables.$colors , main );
    border: none;

    &:hover{
      background-color: #fff;
      color: map-get(variables.$colors , main );
      border: 1px solid map-get(variables.$colors , main );
    }
  }

  @else if $col == 0{
    background-color: none;

    &:hover{
      background-color: #fff;
      color: map-get(variables.$colors , main );
    }
  }

  
  a{
    text-align: center;
    // width: 355px;
    // height: $mobHeight * 1px;
    @include flexer;
    @include para(18);
    font-style: normal;
    font-weight: 700;
    line-height: 23px;
    color: #fff;
  }

  // @include query(mobile){
  //     width: 328px;
  //     height: 53px;
  // }
}

@mixin testi{
  @include flexCol;
    padding: 20px 60px;
  width: 100%;
  background: #F9F9F9;

  .top{
      display: none;
  }

  .mid{
      display: none;
  }

  .line{
      display: none;
  }

  .bot{
      @include flexer;

      ul{
          @include flexer;
          gap: 30px;
          // justify-self: center;

          li{
              @include flexer;
              gap: 12px;

              div{
                  @include flexColStart;

                  h5{
                      @include head(48, -1);
                      line-height: 59px;
                  }

                  p{
                      @include para(18);
                      letter-spacing: 0.2px;
                      line-height: normal;
                      color: #585858;
                  }
              }   
          }
      }
  }
}

@mixin footer{
  @include flexCol;
            padding: 80px 60px 40px;
            width: 100%;
            background: #585858;
            gap: 60px;
            //build

            .top{
                @include flexBet;
                width: 1100px;
                height: 338px;
                border-radius: 64px;
                border: 1px solid #6D6D6D;
                background: #666;
                padding: 60px;

                h1{
                    @include head(56, 0);
                    width: 631px;
                }

                a{
                    @include cta(1);
                }
            }

            footer{
                @include flexBet;
                width: 100%;

                .left{
                    @include flexCol;
                    gap: 15px;

                    ul{
                        @include flexer;
                        gap: 20px;
                    }   
                }

                .right{
                    @include flexCol;
                    gap: 20px;

                    ul{
                        @include flexer;
                        gap: 30px;

                        a{
                            @include head(18, 0);
                            line-height: 23px;
                        }
                    }

                    p{
                        @include para(16);
                        line-height: 26px;
                        color: #fff;
                    }
                }
            }
}
@font-face {
  font-family: "D-DIN-Condensed";
  src: url("./webfonts_D-DIN-Condensed/D-DIN-Condensed.ttf.woff") format("woff"), url("./webfonts_D-DIN-Condensed/D-DIN-Condensed.ttf.svg#D-DIN-Condensed") format("svg"), url("./webfonts_D-DIN-Condensed/D-DIN-Condensed.ttf.eot"), url("./webfonts_D-DIN-Condensed/D-DIN-Condensed.ttf.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
}

body {
  font-family: "D-DIN-Condensed", sans-serif;
  font-style: normal;
}
body .header {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px 60px;
  position: fixed;
  top: 0;
  z-index: 1000;
  background-color: #FFF;
}
body .header nav {
  display: flex;
  align-items: center;
  justify-content: center;
}
body .header nav ul {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-evenly;
  gap: 30px;
}
body .header nav ul li {
  text-transform: uppercase;
}
body .header nav ul li a {
  color: #818285;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}
body .header nav ul li a.active-link {
  color: #E7454C;
}
body .hero {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
  width: 100%;
  height: 580px;
  background: url(../images/redRec.png) no-repeat right;
  padding: 20px 60px;
}
body .hero div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  gap: 29px;
  width: 400px;
  height: 300px;
  color: #fff;
}
body .hero div h1 {
  font-size: 111.2px;
  font-weight: 700;
  line-height: 69px;
  color: #000;
  line-height: 89px;
}
body .hero div p {
  font-size: 16px;
  font-weight: 400;
  line-height: 29px;
  font-family: "Manrope", sans-serif;
  height: 52px;
  width: 430px;
  color: #000;
}
@media only screen and (max-width: 481px) {
  body .hero div p {
    text-align: center;
    font-size: 14px;
    line-height: 26.5px;
  }
}
body .hero div a {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 55px;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #fff;
  color: #fff;
  background-color: #E7454C;
  border: none;
  text-transform: capitalize;
}
body .hero div a:hover {
  background-color: #fff;
  color: #E7454C;
  border: 1px solid #E7454C;
}
body .hero div a a {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 29px;
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
  color: #fff;
}
@media only screen and (max-width: 481px) {
  body .hero div a a {
    text-align: center;
    font-size: 14px;
    line-height: 26.5px;
  }
}
body .hero img {
  position: relative;
  left: 5.25%;
  display: none;
}
body .holding {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-top: 80px;
}
body .home {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
body .home .us {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 60px;
  margin: 40px 0;
  gap: 70px;
  width: 100%;
}
body .home .us .img {
  width: 556px;
  height: 660px;
  border-radius: 64px;
}
body .home .us .text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}
body .home .us .text h4 {
  font-size: 18px;
  font-weight: 700;
  line-height: 69px;
  color: #E7454C;
  line-height: 23px;
  color: #E7454C;
}
body .home .us .text h2 {
  font-size: 56px;
  font-weight: 700;
  line-height: 69px;
  color: #000;
}
body .home .us .text p {
  font-size: 18px;
  font-weight: 400;
  line-height: 29px;
  font-family: "Manrope", sans-serif;
  width: 563px;
  height: 348px;
}
@media only screen and (max-width: 481px) {
  body .home .us .text p {
    text-align: center;
    font-size: 14px;
    line-height: 26.5px;
  }
}
body .home .us .text a {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 55px;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #fff;
  color: #fff;
  background-color: #E7454C;
  border: none;
}
body .home .us .text a:hover {
  background-color: #fff;
  color: #E7454C;
  border: 1px solid #E7454C;
}
body .home .us .text a a {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 29px;
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
  color: #fff;
}
@media only screen and (max-width: 481px) {
  body .home .us .text a a {
    text-align: center;
    font-size: 14px;
    line-height: 26.5px;
  }
}
body .home .service {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 40px;
  padding: 20px 60px;
  width: 100%;
}
body .home .service .top {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
body .home .service .top h4 {
  font-size: 18px;
  font-weight: 700;
  line-height: 69px;
  color: #E7454C;
}
body .home .service .top h2 {
  font-size: 56px;
  font-weight: 700;
  line-height: 69px;
  color: #000;
  text-align: center;
  width: 606px;
}
body .home .service .bot {
  display: flex;
  align-items: center;
  justify-content: center;
}
body .home .service .bot .grider {
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, auto);
  gap: 50px;
  left: 10px;
}
body .home .service .bot .grider div {
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: flex-start;
  gap: 13px;
  width: 373px;
}
body .home .service .bot .grider div span {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  width: 294px;
}
body .home .service .bot .grider div span h4 {
  font-size: 32px;
  font-weight: 700;
  line-height: 69px;
  color: #000;
  line-height: 39px;
}
body .home .service .bot .grider div span p {
  font-size: 18px;
  font-weight: 400;
  line-height: 29px;
  font-family: "Manrope", sans-serif;
  letter-spacing: 0.27px;
  width: 292px;
}
@media only screen and (max-width: 481px) {
  body .home .service .bot .grider div span p {
    text-align: center;
    font-size: 14px;
    line-height: 26.5px;
  }
}
body .home .service a {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 55px;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #fff;
  color: #fff;
  background-color: #E7454C;
  border: none;
}
body .home .service a:hover {
  background-color: #fff;
  color: #E7454C;
  border: 1px solid #E7454C;
}
body .home .service a a {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 29px;
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
  color: #fff;
}
@media only screen and (max-width: 481px) {
  body .home .service a a {
    text-align: center;
    font-size: 14px;
    line-height: 26.5px;
  }
}
body .home .process {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 60px;
  background: #585858;
  width: 100%;
  padding: 70px 60px;
}
body .home .process .top {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
body .home .process .top h4 {
  font-size: 18px;
  font-weight: 700;
  line-height: 69px;
  color: #fff;
  line-height: 23px;
}
body .home .process .top h2 {
  font-size: 56px;
  font-weight: 700;
  line-height: 69px;
  color: #fff;
}
body .home .process .top p {
  font-size: 18px;
  font-weight: 400;
  line-height: 29px;
  font-family: "Manrope", sans-serif;
  color: #fff;
}
@media only screen and (max-width: 481px) {
  body .home .process .top p {
    text-align: center;
    font-size: 14px;
    line-height: 26.5px;
  }
}
body .home .process .bot {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}
body .home .process .bot div {
  width: 267px;
  height: 342px;
  border-radius: 64px;
  background: #666;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 15px;
}
body .home .process .bot div h4 {
  font-size: 32px;
  font-weight: 700;
  line-height: 69px;
  color: #fff;
  line-height: 39px;
}
body .home .process .bot div p {
  font-size: 14px;
  font-weight: 400;
  line-height: 29px;
  font-family: "Manrope", sans-serif;
  line-height: 22px;
  color: #D8D8D8;
  text-align: center;
  width: 203px;
}
@media only screen and (max-width: 481px) {
  body .home .process .bot div p {
    text-align: center;
    font-size: 14px;
    line-height: 26.5px;
  }
}
body .home .process a {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 55px;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #fff;
  color: #fff;
  background-color: #E7454C;
  border: none;
}
body .home .process a:hover {
  background-color: #fff;
  color: #E7454C;
  border: 1px solid #E7454C;
}
body .home .process a a {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 29px;
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
  color: #fff;
}
@media only screen and (max-width: 481px) {
  body .home .process a a {
    text-align: center;
    font-size: 14px;
    line-height: 26.5px;
  }
}
body .home .test {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px 60px;
  width: 100%;
  background: #F9F9F9;
  gap: 40px;
}
body .home .test .top {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
body .home .test .top h4 {
  font-size: 18px;
  font-weight: 700;
  line-height: 69px;
  color: #E7454C;
  line-height: 23px;
}
body .home .test .top .tBot {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
  width: 100%;
}
body .home .test .top .tBot h2 {
  font-size: 56px;
  font-weight: 700;
  line-height: 69px;
  color: #000;
}
body .home .test .top .tBot .but {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
}
body .home .test .top .tBot .but button {
  width: 15px;
  height: 28px;
}
body .home .test .mid {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  min-width: 100%;
  overflow: hidden;
}
body .home .test .mid .testimonial {
  width: 350px;
  height: 260px;
  border-radius: 64px;
  background: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  gap: 18px;
}
body .home .test .mid .testimonial p {
  font-size: 16px;
  font-weight: 400;
  line-height: 29px;
  font-family: "Manrope", sans-serif;
  line-height: 18px;
}
@media only screen and (max-width: 481px) {
  body .home .test .mid .testimonial p {
    text-align: center;
    font-size: 14px;
    line-height: 26.5px;
  }
}
body .home .test .mid .testimonial span {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
  width: 320px;
  height: 66px;
  border-radius: 48px;
  border: 1px solid #D8D8D8;
  background: #F9F9F9;
  padding: 10px;
}
body .home .test .mid .testimonial span .hold {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
body .home .test .mid .testimonial span .hold .img {
  width: 56px;
  height: 56px;
  border-radius: 56px;
}
body .home .test .mid .testimonial span .hold .text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
}
body .home .test .mid .testimonial span .hold .text h5 {
  font-size: 18px;
  font-weight: 700;
  line-height: 69px;
  color: #000;
  line-height: 23px;
}
body .home .test .mid .testimonial span .hold .text p {
  font-size: 14px;
  font-weight: 400;
  line-height: 29px;
  font-family: "Manrope", sans-serif;
  line-height: 18px;
  color: #949494;
}
@media only screen and (max-width: 481px) {
  body .home .test .mid .testimonial span .hold .text p {
    text-align: center;
    font-size: 14px;
    line-height: 26.5px;
  }
}
body .home .test .line {
  border: 1px solid #D8D8D8;
  width: 100%;
}
body .home .test .bot {
  display: flex;
  align-items: center;
  justify-content: center;
}
body .home .test .bot ul {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}
body .home .test .bot ul li {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}
body .home .test .bot ul li div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
}
body .home .test .bot ul li div h5 {
  font-size: 48px;
  font-weight: 700;
  line-height: 69px;
  color: #000;
  line-height: 59px;
}
body .home .test .bot ul li div p {
  font-size: 18px;
  font-weight: 400;
  line-height: 29px;
  font-family: "Manrope", sans-serif;
  letter-spacing: 0.2px;
  line-height: normal;
  color: #585858;
}
@media only screen and (max-width: 481px) {
  body .home .test .bot ul li div p {
    text-align: center;
    font-size: 14px;
    line-height: 26.5px;
  }
}
body .home .clients {
  height: 600px;
  gap: 80px;
}
body .home .clients h1 {
  font-size: 56px;
  font-weight: 700;
  line-height: 69px;
  color: #000;
  word-wrap: break-word;
  width: 556px;
  text-align: center;
}
body .home .clients .Marquee {
  display: flex;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  align-items: flex-start;
  gap: 19.597px;
  box-shadow: 0px 12px 72px 0px rgba(0, 0, 0, 0.08);
  background: rgba(0, 0, 0, 0.08);
}
body .home .clients .Marquee .img {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
body .home .clients .Marquee .img div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 171.477px;
  height: 171.477px;
  border-radius: 48.993px;
  background: var(--White, #FFF);
}
body .footer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 80px 60px 40px;
  width: 100%;
  background: #585858;
  gap: 60px;
}
body .footer .top {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
  width: 1100px;
  height: 338px;
  border-radius: 64px;
  border: 1px solid #6D6D6D;
  background: #666;
  padding: 60px;
}
body .footer .top h1 {
  font-size: 56px;
  font-weight: 700;
  line-height: 69px;
  color: #fff;
  width: 631px;
}
body .footer .top a {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 55px;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #fff;
  color: #fff;
  background-color: #E7454C;
  border: none;
}
body .footer .top a:hover {
  background-color: #fff;
  color: #E7454C;
  border: 1px solid #E7454C;
}
body .footer .top a a {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 29px;
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
  color: #fff;
}
@media only screen and (max-width: 481px) {
  body .footer .top a a {
    text-align: center;
    font-size: 14px;
    line-height: 26.5px;
  }
}
body .footer footer {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
  width: 100%;
}
body .footer footer .left {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 15px;
}
body .footer footer .left ul {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
body .footer footer .right {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}
body .footer footer .right ul {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}
body .footer footer .right ul a {
  font-size: 18px;
  font-weight: 700;
  line-height: 69px;
  color: #fff;
  line-height: 23px;
}
body .footer footer .right p {
  font-size: 16px;
  font-weight: 400;
  line-height: 29px;
  font-family: "Manrope", sans-serif;
  line-height: 26px;
  color: #fff;
}
@media only screen and (max-width: 481px) {
  body .footer footer .right p {
    text-align: center;
    font-size: 14px;
    line-height: 26.5px;
  }
}
body .about {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
body .about .hero {
  background: url(../images/about.png) no-repeat center;
}
body .about .hero img {
  display: block;
}
body .about .hero h1 {
  color: #FFF;
}
body .about .who {
  padding: 80px 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
}
body .about .who .img {
  position: relative;
  right: 34px;
  width: 556px;
  height: 660px;
  border-radius: 64px;
  background: url(../images/group-afro-americans-working-together\ \(1\)\ 1.png);
}
body .about .who .text {
  position: relative;
  right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  width: 500px;
}
body .about .who .text h1 {
  font-size: 56px;
  font-weight: 700;
  line-height: 69px;
  color: #000;
}
body .about .who .text p {
  font-size: 18px;
  font-weight: 400;
  line-height: 29px;
  font-family: "Manrope", sans-serif;
  height: 290px;
  word-wrap: break-word;
  color: #585858;
  text-align: justify;
}
@media only screen and (max-width: 481px) {
  body .about .who .text p {
    text-align: center;
    font-size: 14px;
    line-height: 26.5px;
  }
}
body .about .test {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px 60px;
  width: 100%;
  background: #F9F9F9;
  padding: 80px 60px;
  background-color: #FFF;
}
body .about .test .top {
  display: none;
}
body .about .test .mid {
  display: none;
}
body .about .test .line {
  display: none;
}
body .about .test .bot {
  display: flex;
  align-items: center;
  justify-content: center;
}
body .about .test .bot ul {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}
body .about .test .bot ul li {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}
body .about .test .bot ul li div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
}
body .about .test .bot ul li div h5 {
  font-size: 48px;
  font-weight: 700;
  line-height: 69px;
  color: #000;
  line-height: 59px;
}
body .about .test .bot ul li div p {
  font-size: 18px;
  font-weight: 400;
  line-height: 29px;
  font-family: "Manrope", sans-serif;
  letter-spacing: 0.2px;
  line-height: normal;
  color: #585858;
}
@media only screen and (max-width: 481px) {
  body .about .test .bot ul li div p {
    text-align: center;
    font-size: 14px;
    line-height: 26.5px;
  }
}
body .about .misVis {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: var(--Grey-6, #F9F9F9);
  padding: 0 60px 60px;
  gap: 60px;
}
body .about .misVis .top {
  display: flex;
  align-items: center;
  justify-content: center;
}
body .about .misVis .top div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 474px;
  height: 126.4px;
  align-self: stretch;
  background: #fff;
  cursor: pointer;
  border-bottom: 4px solid transparent;
}
body .about .misVis .top div.active {
  border-bottom-color: #e7454c;
}
body .about .misVis .top div.inactive h1 {
  color: #949494;
}
body .about .misVis .top div h1 {
  font-size: 48px;
  font-weight: 700;
  line-height: 69px;
  color: #000;
}
body .about .misVis .top div h1.inactive {
  color: #949494;
}
body .about .misVis .bot p {
  font-size: 18px;
  font-weight: 400;
  line-height: 29px;
  font-family: "Manrope", sans-serif;
  text-align: center;
  color: var(--Grey-1, #585858);
}
@media only screen and (max-width: 481px) {
  body .about .misVis .bot p {
    text-align: center;
    font-size: 14px;
    line-height: 26.5px;
  }
}
body .contact {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
body .contact .hero {
  background: url(../images/contact.png) no-repeat center;
}
body .contact .hero img {
  display: block;
}
body .contact .hero div {
  width: 420px;
}
body .contact .hero div h1 {
  color: #FFF;
}
body .contact .feel {
  padding: 40px 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
  width: 100px;
}
body .contact .feel .top {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 457px;
}
body .contact .feel .top h1 {
  font-size: 56px;
  font-weight: 700;
  line-height: 69px;
  color: #000;
}
body .contact .feel .top p {
  font-size: 18px;
  font-weight: 400;
  line-height: 29px;
  font-family: "Manrope", sans-serif;
  color: #585858;
}
@media only screen and (max-width: 481px) {
  body .contact .feel .top p {
    text-align: center;
    font-size: 14px;
    line-height: 26.5px;
  }
}
body .contact .feel .bot {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}
body .contact .feel .bot .hold {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 364px;
  height: 314px;
  border-radius: 64px;
  background: var(--Grey-6, #F9F9F9);
  gap: 15px;
}
body .contact .feel .bot .hold .img {
  width: 96px;
  height: 96px;
  background: url(../images/loc.svg) 50% 33% no-repeat;
  filter: drop-shadow(0px 12px 72px rgba(0, 0, 0, 0.08));
  border-radius: 50%;
}
body .contact .feel .bot .hold .img2 {
  background-image: url(../images/mail.svg);
}
body .contact .feel .bot .hold .img3 {
  background-image: url(../images/call.svg);
}
body .contact .feel .bot .hold h4 {
  font-size: 32px;
  font-weight: 700;
  line-height: 69px;
  color: #000;
  line-height: 39px;
}
body .contact .feel .bot .hold p {
  font-size: 18px;
  font-weight: 400;
  line-height: 29px;
  font-family: "Manrope", sans-serif;
  color: #585858;
  text-align: center;
}
@media only screen and (max-width: 481px) {
  body .contact .feel .bot .hold p {
    text-align: center;
    font-size: 14px;
    line-height: 26.5px;
  }
}
body .contact .touch {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 60px;
  gap: 30px;
}
body .contact .touch .form {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  right: 20px;
}
body .contact .touch .form h1 {
  font-size: 56px;
  font-weight: 700;
  line-height: 69px;
  color: #000;
}
body .contact .touch .form p {
  font-size: 18px;
  font-weight: 400;
  line-height: 29px;
  font-family: "Manrope", sans-serif;
  color: #585858;
}
@media only screen and (max-width: 481px) {
  body .contact .touch .form p {
    text-align: center;
    font-size: 14px;
    line-height: 26.5px;
  }
}
body .contact .touch .form form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
}
body .contact .touch .form form label {
  font-size: 16px;
  font-weight: 700;
  line-height: 69px;
  color: #000;
  line-height: 20px;
}
body .contact .touch .form form input {
  width: 554px;
  height: 48px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--Grey-5, #D8D8D8);
  background: var(--White, #FFF);
  padding: 10px;
}
body .contact .touch .form form input::-moz-placeholder {
  font-size: 14px;
  font-weight: 400;
  line-height: 29px;
  font-family: "Manrope", sans-serif;
  color: #949494;
  line-height: 18px;
}
body .contact .touch .form form input::placeholder {
  font-size: 14px;
  font-weight: 400;
  line-height: 29px;
  font-family: "Manrope", sans-serif;
  color: #949494;
  line-height: 18px;
}
@media only screen and (max-width: 481px) {
  body .contact .touch .form form input::-moz-placeholder {
    text-align: center;
    font-size: 14px;
    line-height: 26.5px;
  }
  body .contact .touch .form form input::placeholder {
    text-align: center;
    font-size: 14px;
    line-height: 26.5px;
  }
}
body .contact .touch .form form .hold {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
}
body .contact .touch .form form .hold textarea {
  width: 554px;
  height: 144px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--Grey-5, #D8D8D8);
  background: var(--White, #FFF);
  padding: 10px;
}
body .contact .touch .form form #hold {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}
body .contact .touch .form form #hold div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}
body .contact .touch .form form #hold div input {
  width: 250px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--Grey-5, #D8D8D8);
  background: var(--White, #FFF);
}
body .contact .touch .form form button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 55px;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #fff;
  color: #fff;
  background-color: #E7454C;
  border: none;
  width: 554px;
  cursor: pointer;
}
body .contact .touch .form form button:hover {
  background-color: #fff;
  color: #E7454C;
  border: 1px solid #E7454C;
}
body .contact .touch .form form button a {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 29px;
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
  color: #fff;
}
@media only screen and (max-width: 481px) {
  body .contact .touch .form form button a {
    text-align: center;
    font-size: 14px;
    line-height: 26.5px;
  }
}
body .contact .touch .img {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  height: 660px;
  width: 556px;
  border-radius: 64px;
  background: url(../images/smiling-portrait-confident-young-businesswoman-holding-digital-tablet-talking-mobile-phone\ 1.png) no-repeat;
  left: 25px;
}
body .contact .test {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px 60px;
  width: 100%;
  background: #F9F9F9;
  padding: 80px 60px;
  background-color: #FFF;
}
body .contact .test .top {
  display: none;
}
body .contact .test .mid {
  display: none;
}
body .contact .test .line {
  display: none;
}
body .contact .test .bot {
  display: flex;
  align-items: center;
  justify-content: center;
}
body .contact .test .bot ul {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}
body .contact .test .bot ul li {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}
body .contact .test .bot ul li div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
}
body .contact .test .bot ul li div h5 {
  font-size: 48px;
  font-weight: 700;
  line-height: 69px;
  color: #000;
  line-height: 59px;
}
body .contact .test .bot ul li div p {
  font-size: 18px;
  font-weight: 400;
  line-height: 29px;
  font-family: "Manrope", sans-serif;
  letter-spacing: 0.2px;
  line-height: normal;
  color: #585858;
}
@media only screen and (max-width: 481px) {
  body .contact .test .bot ul li div p {
    text-align: center;
    font-size: 14px;
    line-height: 26.5px;
  }
}
body .serv {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
body .serv .hero {
  background: url(../images/services.png) no-repeat center;
}
body .serv .hero img {
  display: block;
}
body .serv .hero div {
  width: 480px;
}
body .serv .hero div h1 {
  color: #FFF;
}
body .serv .hero div p {
  color: #FFF;
}
body .serv .lHold {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 80px 60px;
  gap: 50px;
}
body .serv .lHold .img {
  position: relative;
  right: 20px;
  width: 556px;
  height: 660px;
  border-radius: 64px;
}
body .serv .lHold .text {
  position: relative;
  left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}
body .serv .lHold .text h2 {
  font-size: 56px;
  font-weight: 700;
  line-height: 69px;
  color: #000;
}
body .serv .lHold .text p {
  font-size: 18px;
  font-weight: 400;
  line-height: 29px;
  font-family: "Manrope", sans-serif;
  color: #585858;
}
@media only screen and (max-width: 481px) {
  body .serv .lHold .text p {
    text-align: center;
    font-size: 14px;
    line-height: 26.5px;
  }
}
body .serv .lHold .text ul {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}
body .serv .lHold .text ul li {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 18px;
  font-weight: 400;
  line-height: 29px;
  font-family: "Manrope", sans-serif;
  color: #585858;
}
@media only screen and (max-width: 481px) {
  body .serv .lHold .text ul li {
    text-align: center;
    font-size: 14px;
    line-height: 26.5px;
  }
}
body .serv .lHold .text ul li .red {
  width: 16px;
  height: 16px;
  background: #E7454C;
}
body .serv .rHold {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 80px 60px;
  gap: 50px;
}
body .serv .rHold .img {
  position: relative;
  left: 20px;
  width: 556px;
  height: 660px;
  border-radius: 64px;
}
body .serv .rHold .text {
  position: relative;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}
body .serv .rHold .text h2 {
  font-size: 56px;
  font-weight: 700;
  line-height: 69px;
  color: #000;
}
body .serv .rHold .text p {
  font-size: 18px;
  font-weight: 400;
  line-height: 29px;
  font-family: "Manrope", sans-serif;
  color: #585858;
}
@media only screen and (max-width: 481px) {
  body .serv .rHold .text p {
    text-align: center;
    font-size: 14px;
    line-height: 26.5px;
  }
}
body .serv .rHold .text ul {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}
body .serv .rHold .text ul li {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 18px;
  font-weight: 400;
  line-height: 29px;
  font-family: "Manrope", sans-serif;
  color: #585858;
}
@media only screen and (max-width: 481px) {
  body .serv .rHold .text ul li {
    text-align: center;
    font-size: 14px;
    line-height: 26.5px;
  }
}
body .serv .rHold .text ul li .red {
  width: 16px;
  height: 16px;
  background: #E7454C;
}
body .lead {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
body .lead .hero {
  background: url(../images/leader.png) no-repeat center;
}
body .lead .hero img {
  display: block;
}
body .lead .hero div {
  width: 565px;
}
body .lead .hero div h1 {
  color: #FFF;
}
body .lead .hero div p {
  color: #FFF;
}
body .lead .board {
  margin: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 80px;
  row-gap: 50px;
  -moz-column-gap: 80px;
       column-gap: 80px;
}
body .lead .board h1 {
  font-size: 56px;
  font-weight: 700;
  line-height: 69px;
  color: #000;
}
body .lead .board .hold {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  gap: 25px;
}
body .lead .board .hold .holder {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
body .lead .board .hold .holder img {
  width: 280px;
  height: 400px;
  border-radius: 72.316px;
}
body .lead .board .hold .holder:last-child {
  position: relative;
  left: 109%;
}
body .lead .board .hold .holder:nth-child(2) {
  height: 360px;
  align-self: flex-end;
}
body .lead .board .hold .holder h5 {
  font-size: 32px;
  font-weight: 700;
  line-height: 69px;
  color: #fff;
  color: #818285;
  line-height: 39px;
}
body .lead .board .hold .holder p {
  font-size: 16px;
  font-weight: 400;
  line-height: 29px;
  font-family: "Manrope", sans-serif;
  color: #585858;
}
@media only screen and (max-width: 481px) {
  body .lead .board .hold .holder p {
    text-align: center;
    font-size: 14px;
    line-height: 26.5px;
  }
}
body .lead .board .hold .holder:hover {
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out;
}
body .lead .board .modal-overlay {
  background: rgba(0, 0, 0, 0.7); /* Adjust the opacity as needed */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 997; /* Ensure it appears below the modal */
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
body .lead .board .modal {
  padding: 65px 20px 70px;
  width: 990px;
  height: 510px;
  border-radius: 50px;
  background: var(--White, #FFF);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  top: 45%;
  left: 45%;
  margin-top: -200px;
  margin-left: -425px;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
}
body .lead .board .modal.open {
  opacity: 1;
}
body .lead .board .modal.open .modal-overlay {
  visibility: visible;
  opacity: 1;
  z-index: 999;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.5);
}
body .lead .board .modal button {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 30.526px;
  height: 30.526px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(../images/iconoir_cancel.svg) no-repeat center;
}
body .lead .board .modal button:hover {
  cursor: pointer;
}
body .lead .board .modal div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
body .lead .board .modal div img {
  width: 302.863px;
  height: 387.937px;
  border-radius: 72.596px;
}
body .lead .board .modal div span {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}
body .lead .board .modal div span h5 {
  font-size: 48px;
  font-weight: 700;
  line-height: 69px;
  color: #fff;
  color: #818285;
  line-height: 59px;
}
body .lead .board .modal div span p {
  font-size: 16px;
  font-weight: 400;
  line-height: 29px;
  font-family: "Manrope", sans-serif;
  line-height: 34.342px;
  text-align: justify;
}
@media only screen and (max-width: 481px) {
  body .lead .board .modal div span p {
    text-align: center;
    font-size: 14px;
    line-height: 26.5px;
  }
}
body .lead .board .modal .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 998;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
body .lead .board.modal-open {
  overflow: hidden;
}
body .lead .modal-open {
  overflow: hidden;
}/*# sourceMappingURL=style.css.map */
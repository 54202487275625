@font-face {
    font-family: 'D-DIN-Condensed';
    src:url('./webfonts_D-DIN-Condensed/D-DIN-Condensed.ttf.woff') format('woff'),
        url('./webfonts_D-DIN-Condensed/D-DIN-Condensed.ttf.svg#D-DIN-Condensed') format('svg'),
        url('./webfonts_D-DIN-Condensed/D-DIN-Condensed.ttf.eot'),
        url('./webfonts_D-DIN-Condensed/D-DIN-Condensed.ttf.eot?#iefix') format('embedded-opentype'); 
    font-weight: normal;
    font-style: normal;
}

$font: (main:('D-DIN-Condensed', sans-serif), para:('Manrope', sans-serif));
$colors: (links: #818285, main: #E7454C, tran: #585858);